<template>
  <div class="outermost">
    <van-pull-refresh
      v-model="isLoading"
      @refresh="onRefresh"
      :success-text="refreshSuccessText"
      :success-duration="1500"
    >
      <van-list
        v-model="loading"
        :finished="finished"
        finished-text="暂无更多"
        @load="onLoad"
      >
        <div
          class="list-background"
          v-for="item in list"
          :key="item.id"
          @click="jumpDetails(item)"
        >
          <div class="list-img">
            <img :src="item.postersUrl[0]" alt="" />
          </div>
          <div class="list-content">
            <div class="list-content-title">
              {{ item.name }}
            </div>
            <div class="list-content-tiem">{{ item.startTime }}</div>
          </div>
        </div>
      </van-list>
    </van-pull-refresh>
  </div>
</template>

<script>
import { getMyApply } from '@/api/me'
import Layout from '@/components/layout.vue'
export default {
  components: {
    Layout
  },
  data() {
    return {
      list: [],
      loading: false,
      finished: false,
      isLoading: false,
      pageNo: 1,
      pageSize: 10,
      refreshSuccessText: '刷新成功',
      title: ''
    }
  },
  created() {},
  methods: {
    async onLoad() {
      try {
        // 1. 请求获取数据
        const result = await getMyApply({
          pageNo: this.pageNo,
          pageSize: this.pageSize
        })

        const list = result.result.records

        const filterList = list.filter((item) => {
          if (item.activity_status !== 1 && item.activity_status !== 5) {
            return true
          } else {
            return false
          }
        })
        filterList.forEach((item) => {
          item.postersUrl = item.postersUrl.split(',')
        })

        // 2. 把请求结果数据放到 list 数组中

        // 数组的展开操作符，它会把数组元素依次传入
        this.list.push(...filterList)
        // 判断活动时间是否在当前时间段

        // 3. 本次数据加载结束之后要把加载状态设置为结束
        this.loading = false

        // 4. 判断数据是否全部加载完成
        if (result.result.records.length) {
          // 更新获取下一页数据的时间戳
          this.pageNo++
        } else {
          // 没有数据了，将 finished 设置为 true，不再 load 加载更多了
          this.finished = true
        }
      } catch (err) {
        // 请求失败了，loading 也需要关闭
        this.loading = false
      }
    },
    onRefresh() {
     this.list = []
    this.pageNo = 1
    this.onLoad()
    },
    // 跳转详情
    jumpDetails(item) {
      this.$router.push({
        name: 'activity-listDetails',
        params: {
          id: item.id
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.outermost {
  background-color: #f2f3f5;
  .layout /deep/ .main {
    padding-bottom: 0;
  }
  .hd-left {
    display: flex;
    align-items: center;
    div {
      width: 10px;
      height: 10px;
      border-top: 2px solid #ffffff;
      border-right: 2px solid #ffffff;
      transform: rotate(225deg);
    }
    span {
      font-size: 18px;
      margin-left: 4px;
    }
  }
  .list-background {
    margin: 0 10px;
    margin-top: 8px;
    margin-bottom: 2px;
    height: 92px;
    background: #ffffff;
    border-radius: 8px;
    padding: 15px 14px;
    display: flex;
    justify-content: space-between;
    .list-img {
      width: 150px;
      height: 100%;
      img {
        border-radius: 4px;
        width: 100%;
        height: 100%;
      }
    }
    .list-content {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .list-content-title {
        width: 164px;
        font-size: 15px;
        color: #12151b;
        line-height: 1.2;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 3;
      }
      .list-content-tiem {
        font-size: 12px;
        color: #464a55;
      }
    }
  }
}
</style>