<template>
  <div class="outermost">
    <layout class="layout" :hd="true" :ft="false">
      <!-- 右边按键 -->
      <div slot="rt"></div>
      <!-- 左边按键 -->
      <div slot="le" class="hd-left" @click="returnHome">
        <div></div>
        <span>我的报名</span>
      </div>
      <van-tabs  :class="{'title':$store.state.user.elderModel}" title-active-color="#10955B" color="#10955B" v-model="active">
        <van-tab title="我的活动">
          <myActivity v-if="!$store.state.user.elderModel" />
          <myActivityElder v-else/>
        </van-tab>
        <van-tab  title="我的课程">
          <myCurriculum  v-if="!$store.state.user.elderModel"/>
          <myCurriculumElder v-else/>
        </van-tab>
      </van-tabs>
    </layout>
  </div>
</template>

<script>
import layout from '@/components/layout.vue'
import myActivity from './components/my-activity'
import myActivityElder from './components/my-activity-elder.vue'
import myCurriculum from './components/my-curriculum'
import myCurriculumElder from './components/my-curriculum-elder.vue'
export default {
  components: {
    layout,
    myActivity,
    myCurriculum,
    myActivityElder,
    myCurriculumElder
    
  },
  data() {
    return {
      active: 0
    }
  },
  created() {},
  methods: {
    // 返回上一页
    returnHome() {
      this.$router.go(-1)
    }
  }
}
</script>

<style lang="less" scoped>
.title /deep/ .van-tab__text--ellipsis{
  font-size: 20px !important;
}
.outermost {
  background-color: #f2f3f5;
  .layout /deep/ .main {
    padding-bottom: 0;
  }
  .hd-left {
    display: flex;
    align-items: center;
    div {
      width: 10px;
      height: 10px;
      border-top: 2px solid #ffffff;
      border-right: 2px solid #ffffff;
      transform: rotate(225deg);
    }
    span {
      font-size: 18px;
      margin-left: 4px;
    }
  }
}
</style>


